import React, { Component } from 'react';
import BlogHeader from './BlogHeader';
import { Redirect, withRouter } from 'react-router-dom';
import CommonValues from '../Common/util';

class MiddleHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        }
    }

    onrequestdemoclick = () => {
        window.location.replace(`${process.env.REACT_APP_App_Url}/requestdemo`)
    }
    onaboutClick = () => {
        this.props.history.push("/about")
    }

    render() {
        return (
            <>
                <div className=" container-fluid bg-community-banner banner-color  position_fixes bg-height bg-left-0">
                    <div className="bg-img-banner-text">
                        <div className="row align-items-center justify-content-center">
                            <div className="container text-center main-head-title">
                                <h2 className="mb-1 banner-heading text-white">Insurance Technology AppMarket</h2>
                                <span className="text-16 text-secondary-transperant">Connecting Buyers and Sellers through
                                    Insurance Microservices</span>
                                <div className="mt-1">
                                    <span className=" mt-3 text-yellow create-gig-header">Set up your own FREE whitelabeled AppMarket with ready-to-use services, and receive a $25 credit.
                                    <button className="btn btn-yellow btn-yellow-create ml-2" onClick={this.onaboutClick}>Create GigMarket</button></span>
                                    <span class=" create-gig-btn "><button  onClick={this.onaboutClick} className="btn btn-yellow ml-2 create-Own-GigMarket-btn">Create Your Own GigMarket?</button></span>

                                    {CommonValues.GetToken() !== null ?
                                        null :
                                        <div className=" mt-2 d-inline-block ">
                                            <button className="btn btn-yellow-request-demo btn-yellow-create ml-2" onClick={this.onrequestdemoclick}>Request a Demo</button>
                                        </div>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                    <BlogHeader></BlogHeader>
                </div>

            </>
        )
    }
}

export default withRouter(MiddleHeader)