import React, { Component } from 'react'
import IgigLogo from '../assets/images/new-images/insurancegig_logo.png';
import Igig from '../assets/images/new-images/InsuranceGIG.png';
import chromeExtImg from '../assets/images/IG_ChromeExtensionButton.png';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';

class AboutHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    onAddCromeExtensionClick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'PF about page',
            action: 'Click on download chrome extension button from PF about page',
        });
        window.open("https://chrome.google.com/webstore/detail/insurancegig/pidaodnmlepjkmkldnfommgpckflndfg", "_blank")
    }
    onGetDemoClick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Request Demo Page',
            action: 'Click on Request Demo  button from  about page',
        });
        this.props.history.push("/requestdemo");

    }
    onRegisterclick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Register page',
            action: 'Click on register button from PF about page',
        });
        // this.props.history.push("/agencyonboarding?utm_source=PF_about_page");
        window.location.replace(`${process.env.REACT_APP_App_Url}/agencyonboarding`)

    };

    render() {

        return (
            <>
                <div className="row">
                    <div className="col-md-12 d-flex align-items-center justify-content-between header-fixed mob-header">
                        <div className="igig-logo">
                            <img src={IgigLogo} alt="" className="logo-insgig " onClick={this.props.onLogoClick} />
                            <img src={Igig} alt="" className="logo-insgig-text ml-2" onClick={this.props.onLogoClick} />
                        </div>
                        <div >

                            <button
                                className="btn btn-primary-outline-about premfichrom mob-req-btn prem-quote h-auto displayfordesktopview"
                                onClick={this.onRegisterclick}
                            >
                                One Click Premium Finance Quote
                            </button>

                            <button
                                className="btn btn-primary-outline-about about-pf-btn displayformobileview signup-btn-pf"
                                onClick={this.onRegisterclick}
                            >
                                Sign Up
                            </button>
                            <button className="btn  btn-yellow-about about-pf-btn " onClick={() => this.onGetDemoClick()}>Request Demo</button>

                            <button className="btn btn-yellow-about about-btn" onClick={this.props.onCTAVlick} >Create GigMarket</button>
                        </div>
                    </div>


                </div>
            </>
        )
    }
}
export default withRouter(AboutHeader)