
import React, { Component } from 'react'
import ListingFooter from '../Common/ListingFooter';
import MiddleHeader from '../Common/MiddleHeader';
import MainHeader from '../OtherCommonPages/MainHeader';
import CommonValues from '../Common/util';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import NcLogo from '../assets/images/nc.png'
export default class NowcertsSso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: ""
        }
    }
    componentDidMount = () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        const token = searchParams.get('token');
        this.validateNowcertsToken(token)
    }

    validateNowcertsToken = (token) => {

        this.setState({
            loading: true,
        });
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Local_Url}/api/account/validateNowcertsToken?token=${token}`,

        };

        axios(config)
            .then((response) => {
                CommonValues.SetValues(
                    response.data.token,
                    response.data.userId,
                    response.data.orgName,
                    response.data.userName,
                    response.data.roleId,
                    response.data.avatarName,
                    response.data.firstName,
                    response.data.lastName,
                    response.data.iconURL,
                    response.data.hasGIGMarket,
                    response.data.slugURL,
                    response.data.headerText,
                    response.data.tenantId
                );
                this.setState({
                    loading: false,
                });
                 window.location.href = `/${response.data.slugURL}/myservices`;
            })
            .catch((error) => {
                console.log(error.response);
                this.setState({
                    loading: false,
                });
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout();
                    }
                } else {
                    this.setState({
                        errorMessage: "Unknown error while getting details!"
                    })
                }
            });

    }
    render() {
        return (
            <div className="nowcerts-sso-page-main">
                <MainHeader></MainHeader>
                <div className="row mr-0 ml-0  bg-community-banner error-page-top position-relative">
                    <div className="leftbg app-bg1 ">
                    </div>
                    <div className="midbg ">
                        <div className="continer2 text-left pt-4">
                            <MiddleHeader heading1="Welcome to InsuranceGIG" heading2="Connecting Buyers and Sellers through Insurance Microservices"></MiddleHeader>
                            <div className="p-4 mid-sec float-left">
                                <div className="mid-sec-inner pt-3">
                                    <div className="col-md-8 float-left mid1-left text-center">
                                    </div>
                                </div>
                                <div className="mid-sec-inner errorpagetext">
                                    {this.state.loading ?
                                        <div className='bg-dark authenticating-block'>
                                            <img src={NcLogo} height="70px" />
                                            <div className=' mt-3 mb-3 '>
                                                <span className=' text-light'>Authenticating</span>

                                            </div>
                                            <IgigLoadingIcon />
                                        </div>
                                        :
                                        null}

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="rightbg app-bg2">

                    </div>
                </div>
                <div className="container-fluid  mt-2" >
                    <div className="row pb-2 pt-2">
                        <div className="col-md-12 col-12 text-left">

                        </div>
                    </div>
                </div>
                <ListingFooter></ListingFooter>
            </div>
        )
    }
}