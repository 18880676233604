import React, { Component } from "react";
import GalleryHash from "../assets/images/aboutgigmarket-img.png";
import GalleryIcopurple from "../assets/images/about-second-img.png";
import Pizzaleimg from "../assets/images/pizzaleimg.png";
import GigmarketApps from "./GigmarketApps";
import ListingFooter from "../Common/ListingFooter";
import AboutHeader from "./AboutHeader";
import ReactPlayer from "react-player";

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isMuted:true
    };
  }

  onVideoPuase=()=>{
    this.setState({isMuted : false});
  }
  onLogoClick = () => {
    this.props.history.push("/");
  };
  onCTAVlick = () => {
    window.location.replace(`${process.env.REACT_APP_App_Url}/agencyonboarding`)
    // this.props.history.push("/agencyonboarding?utm_source=about_topbanner");
  };
  onRegisterclick = () => {
    window.location.replace(`${process.env.REACT_APP_App_Url}/agencyonboarding`)
    // this.props.history.push("/agencyonboarding?utm_source=about_page");
  };
  onRequestService = () => {
    this.props.history.push("/requestservice");
  };
  onRequestdemoclick =()=>{
    // this.props.history.push("/requestdemo");
    window.location.replace(`${process.env.REACT_APP_App_Url}/requestdemo`)

  }

  render() {
    return (
      <div className="About-page">
        <div className="meet-again">
          <div className="meet-again-main">
            <div className="container-fluid">
              <AboutHeader
                onCTAVlick={this.onCTAVlick}
                onLogoClick={this.onLogoClick}
              ></AboutHeader>
              <div className="container ">
                <div className="meet-again-width">
                  <div className="row align-items-center welcome-gigmarket-div">
                    <div className="col-md-6 z-index-1 gigmarket-height welcom-img-gig">
                      <img
                        src={GalleryHash}
                        className="img-fluid gallery-img mob-about-img about-page-mobile"
                        alt=""
                      />
                      <div className="about-page-desktop">
                        <h2>Welcome to GigMarket!</h2>
                        <p>
                          A revolutionary new way to access the latest insurtech
                          on a per transaction basis. If there's technology or
                          service that you really want to use, but they cost
                          $5,000 to $10,000 per year, and you only need them 4-5
                          times per year, you can use InsuranceGIG's
                          white-labeled AppMarket to use that service and pay
                          per transaction!
                        </p>
                        <div className="mt-5 about-mob-btn">
                          <button
                            className="btn about-primary-btn-purple"
                            onClick={this.onRegisterclick}
                          >
                            Register
                          </button>
                          <button
                            className="btn btn-primary-outline-about mob-req-btn h-auto "
                            onClick={this.onRequestdemoclick}
                          >
                            Request Demo
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 text-center z-index-1 welcom-img">
                      <img
                        src={GalleryHash}
                        className="img-fluid gallery-img mob-about-img about-page-desktop "
                        alt=""
                      />
                      <div className="about-page-mobile">
                        <h2>Welcome to GigMarket!</h2>
                        <p className="text-left">
                          A revolutionary new way to access the latest insurtech
                          on a per transaction basis. If there's technology or
                          service that you really want to use, but they cost
                          $5,000 to $10,000 per year, and you only need them 4-5
                          times per year, you can use InsuranceGIG's
                          white-labeled AppMarket to use that service and pay
                          per transaction!
                        </p>
                        <div className="mt-5 about-mob-btn">
                          <button className="btn about-primary-btn-purple"  onClick={this.onRegisterclick}>
                            Register
                          </button>
                          <button
                            className="btn btn-primary-outline-about mob-req-btn h-auto "
                            onClick={this.onRequestdemoclick}
                          >
                            Request Demo
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-purple-about-img text-white">
            <div className="container-fluid meet-again-middle">
              <div className="container">
                <div className=" row mb-4 align-items-center">
                  <div className="col-md-7">
                    <img
                      src={GalleryIcopurple}
                      className="img-fluid about-page-mobile"
                      alt=""
                    />
                    <div className="text-white mb-3 mob-font text-16 lh-160 about-page-desktop">
                      Did you know that there are literally THOUSANDS of
                      insuratech companies out there, building solutions,
                      platforms and services for YOU the agent to be able to
                      work more efficiently, service your clients better and
                      become more successful?
                    </div>
                    <span className="text-16 text-white lh-160 about-page-desktop">
                      What if you could harness the power of all their
                      technology, in one single place, and only pay for what you
                      like and only based on what you use?
                    </span>
                  </div>
                  <div className="col-md-5 mob-about-img">
                    <img
                      src={GalleryIcopurple}
                      className="img-fluid about-page-desktop"
                      alt=""
                    />
                    <div className="text-white mb-3 mob-font text-16 lh-160 about-page-mobile">
                    Did you know that there are literally THOUSANDS of insurtech companies out there, building solutions, platforms and services
                     for YOU the agent to be able to work more efficiently, service your clients better and become more successful ?
                    </div>
                    <span className="text-16 text-white lh-160 about-page-mobile">
                    What if you could harness the power of all their technology, in one single place, and only pay
                     for what you like and only based on what you use?
                    </span>
                  </div>
                </div>
                <div className="row mt-5 align-items-center">
                  <div className="col-md-5 text-center z-index-1">
                    <img src={Pizzaleimg} className="img-fluid" alt="" />
                  </div>
                  <div className="col-md-7 text-right-introduction lh-160">
                    <span className="text-white mb-3 mob-font text-16">
                    Introducing GigMarket - a portal of Apps that is absolutely 100% free to access.
                    </span><br/><br/>
                    <span className="text-16 text-white mob-font">
                    With each application bringing you the powers of the insurtech companies out there.<br/><br/>
                    Now YOU can have access to enterprise-level solutions and services, without the enterprise price tag.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="meet-again-main-third">
            <div className="container-fluid ">
              <div className="market-buyer">
                <div className="row text-center ">
                  <div className="col-md-12 z-index-1">
                    <h2 className="font-weight-bold market-buyer-title">
                      GigMarket is Your One-Stop
                      <br /> Destination to Access Insurtech Apps
                    </h2>
                    <span className="text-16 d-block color-about-third">
                      New Apps are being added on a regular basis to continue to
                      expand your <br />
                      capabilities portfolio.
                    </span>
                    <div className="row ">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 col-lg-6 col-sm-12 about-video">
                        <div className="video-main ">
                          <ReactPlayer
                            muted={this.state.isMuted ? true : false}
                            className="about-third-video react-video" 
                            url={"https://www.youtube.com/watch?v=lvDDqOGTkDo"}
                            playing={true} 
                            loop={true} 
                            controls={true}                  
                            onPause={this.onVideoPuase}
                          />
                        </div>
                      </div>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="network-effects text-center">
            <GigmarketApps></GigmarketApps>
          </div>
          <div className="gigmarket-footer">
            <div className=" footer-gigwidth">
              <div className="row align-items-center m-0">
                <div className="col-md-12">
                  <p className="mb-0 about-bootom-p">
                    Do you have an insurtech App in mind that will help you in
                    your day-to-day work? Let us know what we should add to our
                    GigMarket next.{" "}
                  </p>
                </div>
                {/* <div className="col-md-3  create-gig-bottom-btn">
                  <button
                    className="btn btn-yellow-about-bottom"
                    onClick={this.onRequestService}
                  >
                    Request Service
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <ListingFooter></ListingFooter>
        </div>
      </div>
    );
  }
}
